import { createRouter, createWebHistory } from 'vue-router'
import store from '../store'
import PATHS from './routesMap'

import Auth from '../views/auth/Auth.vue'

const routes = [
  {
    name: 'Auth',
    path: PATHS.AUTH,
    component: Auth,
  },
  {
    name: 'Home',
    path: PATHS.HOME,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/home/Home.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'OrdersNew',
    path: PATHS.ORDERS_NEW,
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/orders/NewOrder.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'Unauthorized',
    path: PATHS.UNAUTHORIZED,
    component: () =>
      import(
        /* webpackChunkName: "unauthorized" */ '../views/unauthorized/Unauthorized.vue'
      ),
  },
  {
    name: 'NotFound',
    path: '/:pathMatch(.*)*',
    component: () =>
      import(
        /* webpackChunkName: "notFound" */ '../views/notFound/NotFound.vue'
      ),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from) => {
  if (store.getters.isLoggedPBOIn && to.name === 'Auth') {
    return { name: 'Home' }
  }
  if (to.meta.requiresAuth) {
    if (!store.getters.isLoggedPBOIn && !localStorage.getItem('routePBO')) {
      return { name: 'Auth' }
    }
  }
})

router.afterEach(async (to, from) => {
  if (!store.getters.isLoggedPBOIn && to.meta.requiresAuth) {
    await store.dispatch('logout')
    router.push(PATHS.AUTH)
  }
})

export default router
