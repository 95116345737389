<template>
  <div
    class="navbar navbar-expand-lg border-bottom border-bottom-white border-opacity-10 navbar-dark bg-secondary"
  >
    <div class="container-fluid">
      <span
        >© {{ year }}
        <a href="https://gruposn.com.br" target="_blank">Grupo SN</a></span
      >

      <ul class="nav">
        <li class="nav-item">
          <a
            :href="whatsapp"
            class="navbar-nav-link navbar-nav-link-icon rounded"
            target="_blank"
          >
            <div class="d-flex align-items-center mx-md-1">
              <i class="ph-whatsapp-logo text-success"></i>
              <span class="d-none d-md-inline-block ms-2">WhatsApp</span>
            </div>
          </a>
        </li>

        <li class="nav-item ms-md-1">
          <button
            @click="logout"
            class="navbar-nav-link btn navbar-nav-link-icon text-primary bg-primary bg-opacity-10 fw-semibold rounded"
            target="_blank"
          >
            <div class="d-flex align-items-center mx-md-1">
              <i class="ph-sign-out me-2" />
              <span class="d-none d-md-inline-block ms-2"> Sair</span>
            </div>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'Footer',

  components: {},

  data() {
    return {
      showAccountModal: false,
      year: new Date().getFullYear(),
      whatsapp:
        'https://api.whatsapp.com/send?phone=553591141573&text=Olá, estou entrando em contato a partir da plataforma de pedidos',
    }
  },

  computed: {
    user() {
      return this.$store.getters.user
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('logout').then(() => {
        localStorage.clear()
        this.$router.push(PATHS.BASE_URL)
      })
    },
  },
}
</script>
