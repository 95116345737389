<template>
  <div
    class="navbar navbar-dark navbar-expand-lg navbar-static border-bottom border-bottom-white border-opacity-10 bg-secondary"
  >
    <div class="container-fluid ps-1">
      <div class="d-flex d-lg-none me-2">
        <button
          type="button"
          class="navbar-toggler sidebar-mobile-main-toggle rounded"
        >
          <i class="ph-list" />
        </button>
      </div>
      <div class="navbar-brand">
        <router-link to="/home" class="d-inline-flex align-items-center">
          <img
            src="@/assets/img/horizon_logo_white.png"
            class="h-32px py-1"
            alt="Logo do Senar"
          />
        </router-link>
      </div>
      <div class="d-xl-none">
        <button
          type="button"
          class="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-navigation"
          aria-expanded="true"
        >
          <i class="ph-list" />
        </button>
      </div>
      <div class="navbar-collapse collapse" id="navbar-navigation">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item ms-xl-1">
            <a href="#" class="navbar-nav-link rounded" aria-expanded="false">
              {{ user.name }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'Navbar',

  components: {},

  data() {
    return {
      showAccountModal: false,
    }
  },

  computed: {
    user() {
      return this.$store.getters.user
    },
  },
}
</script>
