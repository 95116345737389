const BASE_URL = '/'

const AUTH = '/'

const HOME = `${BASE_URL}home`
const ORDERS = `${BASE_URL}orders`
const ORDERS_NEW = `${ORDERS}/new`
const ORDERS_DETAILS = `${ORDERS}/details/:id`
// Sem Autorização
const UNAUTHORIZED = `${BASE_URL}not-authorized`

export default {
  BASE_URL,
  AUTH,
  HOME,
  ORDERS_NEW,
  ORDERS_DETAILS,
  UNAUTHORIZED,
}
