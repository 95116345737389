import api from '@/services/api'

export default {
  state: {},

  mutations: {},

  actions: {
    async getLastOrders({ commit }) {
      try {
        const response = await api.get(`v1/portal/orders/last`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async saveOrder({ commit }, data) {
      try {
        return await api.post(`v1/portal/orders`, data)
      } catch (error) {
        throw error
      }
    },
    async cancelOrder({ commit }, data) {
      try {
        return await api.delete(`v1/portal/orders/${data}`)
      } catch (error) {
        throw error
      }
    },
    async getProducts({ commit }) {
      try {
        const response = await api.get(`v1/portal/products`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
    async getPaymentConditions({ commit }) {
      try {
        const response = await api.get(`v1/portal/payment-conditions`)

        return response && response.data ? response.data : null
      } catch (error) {
        throw error
      }
    },
  },

  getters: {},
}
