<template>
  <div class="d-flex align-items-center w-100 h-100">
    <div class="row w-100 h-100">
      <div class="col-12 d-none d-md-flex col-md-8 bg-theme h-100">
        <div class="d-flex justify-content-center align-items-center"></div>
      </div>
      <div
        class="col-12 col-md-4 p-3 d-flex justify-content-center align-items-center"
      >
        <form class="w-100" @submit.prevent="auth">
          <div class="mb-0">
            <div class="card-body">
              <div class="text-center mb-3">
                <h3 class="mb-0 text-primary"><em>Bem-vindo ao</em></h3>
                <h2 class="text-secondary">BRITAS ONLINE</h2>
              </div>
              <div class="mb-3">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': v$.cgcCfo.$errors.length }"
                    placeholder="00.000.000/0000-00"
                    v-maska="['###.###.###-##', '##.###.###/####-##']"
                    v-model="v$.cgcCfo.$model"
                  />
                  <label>CPF/CNPJ:</label>
                  <span
                    v-if="v$.cgcCfo.required.$invalid"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                  <span
                    v-if="v$.cgcCfo.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    Precisa ter pelo menos
                    {{ v$.cgcCfo.minLength.$params.min }} caracteres
                  </span>
                  <span
                    v-if="v$.cgcCfo.maxLength.$invalid"
                    class="invalid-feedback"
                  >
                    Deve ter no máximo
                    {{ v$.cgcCfo.minLength.$params.min }} caracteres
                  </span>
                </div>
              </div>
              <div class="mb-3">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    :class="{ 'is-invalid': v$.code.$errors.length }"
                    placeholder="XXXX"
                    v-model="v$.code.$model"
                  />
                  <label>Código de Acesso:</label>
                  <span
                    v-if="v$.code.required.$invalid"
                    class="invalid-feedback"
                  >
                    O campo é obrigatório
                  </span>
                  <span
                    v-if="v$.code.minLength.$invalid"
                    class="invalid-feedback"
                  >
                    O código deve ter pelo menos
                    {{ v$.code.minLength.$params.min }} caracteres
                  </span>
                </div>
              </div>
              <div class="mb-3">
                <button
                  type="submit"
                  class="btn btn-primary btn-lg w-100"
                  title="Login"
                  :disabled="v$.$invalid || disableButton"
                >
                  <span v-if="disableButton">
                    <i class="ph-spinner spinner" />
                  </span>
                  <span class="fw-bold" v-else>ACESSAR</span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
<style scoped>
.bg-theme {
  background-image: url('~@/assets/img/background.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
h2 {
  font-size: 2.5rem;
  font-weight: 900;
}
</style>
<script>
import { maska } from 'maska'
import { useVuelidate } from '@vuelidate/core'
import { required, maxLength, minLength } from '@vuelidate/validators'
import PATHS from '@/router/routesMap'

export default {
  name: 'Auth',
  directives: { maska },
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      cgcCfo: '',
      code: '',
      disableButton: false,
    }
  },

  methods: {
    async auth() {
      if (!this.v$.$invalid) {
        this.disableButton = true
        try {
          await this.$store.dispatch('auth', {
            cgcCfo: this.cgcCfo,
            code: this.code,
          })
          this.disableButton = false
          const redirectRoute = localStorage.getItem('routePBO')
          if (redirectRoute) {
            localStorage.removeItem('routePBO')
            window.location.href = redirectRoute
            return
          }
          this.$router.push(PATHS.HOME)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$refs.notification.warning(error.message)
          } else {
            this.$root.$refs.notification.error(
              'Não foi possível se conectar ao servidor.',
            )
          }
        }
      }
    },
  },

  validations() {
    return {
      code: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(5),
      },
      cgcCfo: {
        required,
        minLength: minLength(14),
        maxLength: maxLength(18),
      },
    }
  },

  mounted() {
    if (localStorage.getItem('sessionError')) {
      this.$root.$refs.notification.warning(
        localStorage.getItem('sessionError'),
      )
      localStorage.removeItem('sessionError')
    }
  },
}
</script>
