<template>
  <Navbar v-if="showComponent" />
  <div class="page-content" :class="{ 'vh-100': addClass }">
    <div class="content-wrapper">
      <div class="content-inner">
        <div class="content" :class="addClass ? 'p-0' : 'p-2'">
          <slot />
        </div>
      </div>
      <Footer v-if="showComponent" />
    </div>
  </div>
</template>
<style>
@media (max-width: 990px) {
  .content {
    padding: 0;
  }
}
</style>
<script>
import Navbar from '@/components/commons/Navbar.vue'
import Footer from '@/components/commons/Footer.vue'

export default {
  name: 'Layout',

  components: {
    Navbar,
    Footer,
  },

  data() {
    return {
      notifications: [],
      // showComponent: false,
      isMobile: false,
      routesName: ['Auth', 'Unauthorized', 'NotFound', 'ExternalSupportTicket'],
    }
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 991
    },
  },
  computed: {
    isLoggedPBOIn() {
      return this.$store.getters.isLoggedPBOIn
    },
    addClass() {
      return this.routesName.includes(this.$route.name)
    },

    showComponent() {
      return !this.routesName.includes(this.$route.name)
    },
  },

  mounted() {
    this.onResize()
    window.addEventListener('resize', this.onResize, { passive: true })
  },
}
</script>
