import api from '@/services/api'

export default {
  state: {
    isLoggedPBOIn: !!localStorage.getItem('tokenPBO'),
    user: {
      cgcCfo: 0,
      name: '',
      codCfo: null,
    },
  },
  mutations: {
    AUTH(state, user) {
      state.isLoggedPBOIn = true
      state.user = {
        cgcCfo: user.cgcCfo,
        name: user.name,
        codCfo: user.codCfo,
      }
    },
    LOGOUT(state) {
      state.isLoggedPBOIn = false
      localStorage.removeItem('tokenPBO')
      state.user = {
        cgcCfo: 0,
        name: '',
        codCfo: null,
      }
    },
  },
  actions: {
    async auth({ commit }, credentials) {
      try {
        const response = await api.post('auth-client/login', {
          cgcCfo: credentials.cgcCfo,
          code: credentials.code,
        })
        const data = response && response.data ? response.data : null
        const token = data && data.token ? data.token : ''
        console.log(data)
        const user = {
          cgcCfo: data ? data.cgcCfo : 0,
          name: data ? data.name : '',
          codCfo: data ? data.codCfo : null,
        }
        console.log(user)
        localStorage.setItem('tokenPBO', token)
        commit('AUTH', user)
      } catch (error) {
        commit('LOGOUT')
        throw error
      }
    },

    logout: ({ commit }) => {
      commit('LOGOUT')
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },

  getters: {
    isLoggedPBOIn: (state) => state.isLoggedPBOIn,
    user: (state) => state.user,
  },
}
